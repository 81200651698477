import Vue from "vue";

// BSV Plugin Registration
import { ToastPlugin, ModalPlugin, BootstrapVue } from "bootstrap-vue";

// Google map Plugin
import * as VueGoogleMaps from "vue2-google-maps";

// Luxon Date Formatter
import { DateTime } from "luxon";

// Axios Provide
import { io } from "socket.io-client";
import axios, { baseURL } from "./utils/appAxios";

import store from "./store";

import app from "./main";

import "@/libs/acl";

Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(BootstrapVue);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDPh9IHfNBt1I70gieodbyuRKYrbP9dYBc",
    libraries: "places",
    location: "tr",
  },
});

const showWithKeys = (data, field, joinField) => eval((joinField + field.key).replaceAll(".", "?."));

const showValue = (data, field, joinField) => {
  let value = showWithKeys(data, field, joinField);
  const addition = field.addition ? `${field.addition}` : "";
  if (value || value === 0) {
    if (field.capitalize) value = capitalized(value);
    if (field.formatDateTime) value = formattedDateTime(value);
    if (field.formatDate) value = formattedDate(value);
    if (field.formatToTL) value = formatTotTL(value);
    return value + addition;
  }
  return "-";
};
Vue.prototype.$showValue = showValue;
Vue.prototype.$showWithKeys = showWithKeys;
// Capitalize
const capitalizeValue = (value) => value.charAt(0).toLocaleUpperCase("tr") + value.slice(1);
const capitalized = (value) =>
  value
    ?.split(" ")
    ?.map((item) => capitalizeValue(item))
    ?.join(" ");
Vue.prototype.$capitalize = capitalized;

// Physician Roles
const roles = {
  physician: "İşyeri Hekimi",
  wsExpert: "İş Güvenliği Uzmanı",
  dsp: "DSP",
};

const roleGroups = {
  physician: "Uzman",
  customer: "Firma Kullanıcısı",
};

export const roleCheck = (role) => roles[role];
Vue.prototype.$physicianRole = roleCheck;
export const roleGroupCheck = (role) => roleGroups[role];
Vue.prototype.$roleGroup = roleGroupCheck;

const validate = (ref, callback) => {
  ref?.validate().then((success) => {
    if (success) callback();
  });
};
Vue.prototype.$validate = validate;

export const cleanFields = (data) => {
  Object.keys(data).map((field) => {
    if (Array.isArray(data[field])) data[field] = [];
    else data[field] = null;
  });
  return data;
};
Vue.prototype.$cleanFields = cleanFields;

// EventBus
export const emitter = new Vue();
Vue.prototype.$emitter = emitter;
Vue.prototype.$DateTime = DateTime;
export const formatTotTL = (val) => new Intl.NumberFormat("tr-TR", { style: "currency", currency: "TRY" }).format(val);
export const formattedDateTime = (date) => (date ? DateTime.fromISO(date).toFormat("dd LLLL yyyy - HH:mm", { locale: "tr" }) : "-");
export const formattedDate = (date) => (date ? DateTime.fromISO(date).toFormat("dd LLLL yyyy", { locale: "tr" }) : "-");
Vue.prototype.$FormattedDateTime = formattedDateTime;
Vue.prototype.$FormattedDate = formattedDate;
Vue.prototype.$axios = axios;
Vue.prototype.$formatToTL = formatTotTL;

Vue.prototype.$showAppSidebar = (title, component, item) => {
  store.commit("setAppSidebarOptions", {
    show: true,
    title,
    component,
    item,
  });
};

Vue.prototype.$showAppModalSidebar = (title, component, item) => {
  store.commit("setAppModalSidebarOptions", {
    show: true,
    title,
    component,
    item,
  });
};

Vue.prototype.$showAppModal = (options) => {
  store.commit("setAppModalOptions", {
    show: true,
    title: options?.title,
    component: options?.component,
    item: options?.item,
    size: options?.size,
    noFade: options?.noFade,
    noHeader: options?.noHeader,
    noBodyGap: options?.noBodyGap,
    centered: options?.centered,
  });
};

Vue.prototype.$scrollToElement = (el) => {
  el.target.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "center",
  });
};

Vue.prototype.$fn = (text, count) => text.slice(0, count) + (text.length > count ? "..." : "");
const socket = io(baseURL, {
  transports: ["websocket"],
  reconnectionDelayMax: 10000,
  reconnection: true,
  reconnectionAttempts: 10,
});
socket.on("connect", () => {
  console.log("Socket Connected");
});
socket.on("disconnect", () => {
  console.log("Socket Disconnected");
});
socket.io.on("reconnect", () => {
  socket.emit("config", { token: sessionStorage.getItem("token") });
});
socket.io.on("reconnect_attempt", (val) => {
  console.log("Socket Reconnect Attempt => ", val);
});
socket.io.on("reconnect_error", () => {
  console.log("Socket Reconnecting");
});
socket.io.on("error", () => {
  console.log("Socket Reconnect Failed");
});
Vue.prototype.$socket = socket;

Vue.prototype.$variants = {
  onayda: "warning",
  onaylandı: "success",
  onaylanmadı: "danger",
  reddedildi: "danger",
  arandı: "warning",
  ulaşılamadı: "danger",
  yapılmadı: "danger",
  yapıldı: "success",
  beklemede: "warning",
  Onayda: "warning",
  Yapıldı: "success",
  Onaylandı: "success",
  Yapılmadı: "danger",
  Reddedildi: "danger",
  tamamlandı: "success",
  planed: "warning",
  notCompleted: "danger",
  completed: "success",
  inProgress: "secondary",
};
const mimeTypes = {
  jpg: "image/jpg",
  jpeg: "image/jpeg",
  png: "image/png",
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  csv: "text/csv",
};

Vue.prototype.$acceptMimes = (mimes) => mimes.map((mime) => mimeTypes[mime]).join(",");

Vue.prototype.$dynamicArrayFilter = (array, query, dateKey) =>
  array.filter((item) => {
    for (const key in query) {
      if (query[key]) {
        if (key === "startDate" || key === "endDate") {
          const startTimestamp = new Date(query?.startDate)?.getTime() - 86399999;
          const endTimestamp = new Date(query?.endDate)?.getTime() + 86399999;
          const itemTimestamp = new Date(item[dateKey]);
          if (itemTimestamp <= startTimestamp || itemTimestamp >= endTimestamp) return false;
        } else if (key === "date") {
          const date = new Date(item[dateKey])?.toLocaleDateString("en-CA");
          if (date !== query[key]) return false;
        } else if (eval(`item?.${key}`) !== query[key]) return false;
      }
    }
    return true;
  });

Vue.prototype.$getRepeatedElements = (array, { id, value }) => {
  const items = array.map((item) => ({ id: eval(`item?.${id}`), value: eval(`item?.${value}`) }));
  return [...new Map(items?.map((item) => [item.id, item])).values()];
};

Vue.prototype.$confirm = ({ title, message }, callback) => {
  const h = app.$createElement;
  const messageVNode = h("h5", { class: ["mb-0 my-50"] }, message);
  app.$bvModal
    .msgBoxConfirm([messageVNode], {
      title,
      okVariant: "gradient-danger",
      cancelVariant: "gradient-secondary",
      okTitle: "Evet",
      cancelTitle: "İptal",
      hideHeaderClose: false,
      bodyClass: "text-bold",
    })
    .then((value) => {
      if (value && callback) callback();
    })
    .catch((err) => {
      console.log(err);
    });
};
