import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  state: {
    employee: null,
    supportTeams: [],
    employees: [],
    companyEmployeeFiles: [],
  },
  actions,
  mutations,
  getters,
};
