export default [
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/Additional/error.vue"),
    meta: {
      layout: "full",
      resource: "error",
    },
  },
  {
    path: "/unauthorized",
    name: "unauthorized",
    component: () => import("@/views/Additional/unauthorized.vue"),
    meta: {
      layout: "full",
    },
  },
];
