/* const routes = ['companies', 'certificates', 'periodic-controls', 'company-info', 'regulations', 'dashboard']
 */
let isgRoutes = ["employees", "detection-suggestions", "observations", "incidents", "trainings", "meetings", "plans", "documents", "check-lists", "equipments", "assignments"];

isgRoutes = isgRoutes.map((route) => ({
  path: `/physician/isg/${route}`,
  name: `physician-isg-${route}`,
  props: true,
  component: () => import("@/views/Physicians/ISG/index.vue"),
  props: { mainTab: 0, tabIndex: isgRoutes.indexOf(route) },
  meta: {
    requiresAuth: true,
    resource: "physician",
  },
}));

export default [
  ...isgRoutes,
  {
    path: "/physician/visits/planed",
    name: "physician-visits-planed",
    component: () => import("@/views/Physicians/ISG/index.vue"),
    props: { mainTab: 1, tabIndex: 0 },
    meta: {
      requiresAuth: true,
      resource: "physician",
    },
  },
  {
    path: "/physician/visits/completed",
    name: "physician-visits-completed",
    component: () => import("@/views/Physicians/ISG/index.vue"),
    props: { mainTab: 1, tabIndex: 1 },
    meta: {
      requiresAuth: true,
      resource: "physician",
    },
  },
  {
    path: "/physician/visits/not-completed",
    name: "physician-visits-not-completed",
    component: () => import("@/views/Physicians/ISG/index.vue"),
    props: { mainTab: 1, tabIndex: 2 },
    meta: {
      requiresAuth: true,
      resource: "physician",
    },
  },
  {
    path: "/physician/company-info",
    name: "physician-company-info",
    component: () => import("@/views/Physicians/ISG/index.vue"),
    props: { mainTab: 2 },
    meta: {
      requiresAuth: true,
      resource: "physician",
    },
  },
];
