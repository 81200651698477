import graphqlRequest from "@/utils/graphqlRequest";

export default {
  getAssignedBranches: ({ commit }, variables) => {
    graphqlRequest(
      "/assignments",
      {
        query: `
      query assignments($company:String!, $physician:String!){
        assignments(company:$company, physician: $physician, isAssignmentActive:true){
          companyConfirm branch{ _id name recordNo }
        }
      }
  `,
        variables,
      },
      { loading: "selectBox" }
    )
      .then(({ assignments }) => {
        const data = assignments.map((item) => ({ ...item?.branch, companyConfirm: item.companyConfirm })) ?? [];
        commit("setAssignedBranches", data);
        data.length === 1 && commit("setSelectedBranch", data[0]?._id);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
