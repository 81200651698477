import axios from "@/utils/appAxios";
import router from "@/router";
import { emitter } from "@/global-prototypes";
import app from "@/main";
import { getAbility } from "@/libs/acl/config";
import { getHomeRouteForLoggedInUser, getUserData } from "@/auth/utils";

export const setTokenAndRedirect = (token, first_login, user) => {
  axios.defaults.headers.common.Authorization = token;
  sessionStorage.setItem("token", token);
  delete user.token;
  sessionStorage.setItem("userData", JSON.stringify(user));
  app.$ability.update(getAbility(user?.role?.group));
  if (user?.companies?.length > 1 && user?.first_login) router.push({ name: "company-choose" });
  else router.push({ name: !first_login ? "first-login" : getHomeRouteForLoggedInUser(user?.role?.group)?.name });
};

export default {
  login({ commit }, user) {
    axios
      .post("/login", user, { loading: "table" })
      .then((response) => {
        const user = response.data;
        commit("setUser", response.data);
        setTokenAndRedirect(response.data.token, response.data.first_login, user);
        if (user?.companies?.length === 1) commit("setSelectedCompany", user?.companies[0]);
      })
      .catch((err) => {
        console.log(err);
        emitter.$emit("Notification", {
          title: err.status === 400 ? err?.data?.message : "",
          variant: "danger",
          message: err?.data?.message === "Hesabınıza giriş izni yoktur." ? "Lütfen sistem yöneticiniz ile iletişime geçiniz." : "",
        });
      });
  },
  logout: async ({ commit }, { sendLogoutReq }) => {
    try {
      const token = sessionStorage.getItem("token");
      if (token && sendLogoutReq) await axios.get("/logout");
      commit("cleanStore");
      router.push({ name: "login" }).catch(() => {});
      app.$ability.update(getAbility());
    } catch (error) {
      commit("cleanStore");
      router.push({ name: "login" }).catch(() => {});
      app.$ability.update(getAbility());
    }
  },
  changeTempPassword: ({ commit, getters }, data) => {
    axios
      .patch("/change-temp-password", data, { loading: "table" })
      .then(() => {
        emitter.$emit("Notification", {
          title: "Giriş Başarılı",
          variant: "success",
        });
        commit("setUserFirstLogin");
        const userData = getUserData();
        if (userData?.companies?.length) {
          const selectedCompany = getters.selectedCompany;
          if (!selectedCompany && userData?.companies?.length === 1) {
            const [company] = userData?.companies;
            commit("setSelectedCompany", company);
          } else if (!selectedCompany && userData?.companies?.length > 1) {
            return router.push({ name: "company-choose" }).catch(() => {});
          }
        }
        router.push(getHomeRouteForLoggedInUser(userData?.role?.group));
      })
      .catch((err) => {
        console.log(err);
        emitter.$emit("Notification", {
          title: "Hata Oluştu",
          variant: "danger",
        });
      });
  },
  changePassword: ({ commit }, data) => {
    axios
      .patch("/change-forgotted-password", data, { loading: "table" })
      .then((response) => {
        const user = response.data;
        commit("setUser", response.data);
        setTokenAndRedirect(response.data.token, response.data.first_login, user);
        emitter.$emit("Notification", {
          title: "İşlem Başarılı",
          variant: "success",
          message: "Şifreniz başarıyla güncellendi.",
        });
      })
      .catch((e) => {
        console.log(e);
        emitter.$emit("Notification", {
          title: "Hata Oluştu",
          variant: "danger",
        });
      });
  },
};
