export default {
  setDetectionAndSuggestions: (state, data) => (state.detectionAndSuggestions = data),
  newDetectionAndSuggestion: (state, item) => {
    state.detectionAndSuggestions.data.unshift(item);
    state.detectionAndSuggestions.total++;
  },

  setObservations: (state, data) => (state.observations = data),
  newObservation: (state, item) => {
    state.observations.data.unshift(item);
    state.observations.total++;
  },

  setWsFiles: (state, data) => (state.wsFiles = data),
  newWsFile: (state, data) => state.wsFiles.unshift(data),

  setIncidents: (state, data) => (state.incidents = data),
  newIncident: (state, item) => {
    state.incidents.data.unshift(item);
    state.incidents.total++;
  },

  setTrainings: (state, data) => (state.trainings = data),
  newTraining: (state, item) => {
    state.trainings?.data.unshift(item);
    state.trainings.total++;
  },
  deleteTraining: (state, id) => {
    state.trainings.data = state.trainings.data.filter((i) => i._id !== id);
    state.trainings.total--;
  },
  setTrainingAsStarted: (state, id) => {
    const index = state.trainings.data.findIndex((i) => i._id === id);
    state.trainings.data[index].isStarted = true;
  },
  setEmployeeTrainings: (state, data) => (state.employeeTrainings = data),

  setPlans: (state, data) => (state.plans = data),
  setDocuments: (state, data) => (state.documents = data),
};
