<template>
  <div>
    <b-card class="m-0" body-class="p-0 px-1 ">
      <b-row class="align-items-center">
        <b-col class="tab-container" lg="8">
          <ul :id="id" ref="tabScroll" class="nav nav-pills scrollable-tabs mb-0 py-75" :class="{ 'padding-gap': showArrows }">
            <li v-for="(item, index) in tabs" :key="index" class="nav-item">
              <a :id="'custom-tab-button' + index" class="nav-link" :class="{ active: currentTab === index }" @click="routeCheck($event, item, index)">{{ item.title }}</a>
            </li>
          </ul>
          <Transition name="fade">
            <div v-show="showArrows" ref="leftArrow" class="custom-tab-arrow tab-arrow-left-custom">
              <app-icon-button icon="ChevronLeftIcon" variant="gradient-primary" @click="scrollTabs('left')" />
            </div>
          </Transition>
          <Transition name="fade">
            <div v-show="showArrows" ref="rightArrow" class="custom-tab-arrow tab-arrow-right-custom">
              <app-icon-button icon="ChevronRightIcon" variant="gradient-primary" @click="scrollTabs('right')" />
            </div>
          </Transition>
        </b-col>
        <b-col lg="4">
          <slot name="rightSide" v-bind="{ currentTab }" />
        </b-col>
      </b-row>
    </b-card>
    <b-tabs v-model="currentTab" :nav-class="{ 'm-0': noBottomGap }" pills :lazy="lazy">
      <b-tab
        v-for="(item, key) in tabs"
        :key="item.key"
        title-item-class="d-none"
        :title="item.title"
        :lazy="item.lazy ? item.lazy : lazyOnce ? !mountedTabs.includes(key) : false"
      >
        <slot v-if="!item.component" :name="item.key" />
        <component :is="item.component" v-else />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    lazyOnce: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    noBottomGap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showArrows: false,
      mountedTabs: [],
      currentTab: 0,
    };
  },
  computed: {
    isVerticalMenuCollapsed() {
      return this.$store.state.verticalMenu.isVerticalMenuCollapsed;
    },
  },
  watch: {
    isVerticalMenuCollapsed() {
      setTimeout(() => {
        this.checkOverflow();
      }, 300);
    },
  },
  created() {
    window.addEventListener("resize", this.checkOverflow);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkOverflow);
  },
  mounted() {
    this.$nextTick(() => {
      this.mountedTabs.push(this.currentTab);
      this.checkOverflow();
      setTimeout(() => {
        const el = document.getElementById(`custom-tab-button${this.currentTab}`);
        el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }, 100);
    });
  },
  methods: {
    routeCheck(event, item, key) {
      this.currentTab = key;
      if (this.lazyOnce) {
        if (!this.mountedTabs.includes(key)) {
          this.mountedTabs.push(key);
        }
      }
      item?.route && item?.route !== this.$route.name && this.$router.push({ name: item.route });
      setTimeout(() => {
        event.target.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }, 100);
    },
    scrollTabs(direction) {
      const checkIDElement = document.getElementById(this.id);
      const tabNav = checkIDElement || document.querySelector(".scrollable-tabs");
      const scrollAmount = direction === "left" ? -200 : 200;
      tabNav.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    },
    checkOverflow() {
      const tabScrollContainer = this.$refs.tabScroll;
      const checkIDElement = document.getElementById(this.id);
      const tabNav = checkIDElement || document.querySelector(".scrollable-tabs");
      this.showArrows = tabScrollContainer.clientWidth < tabNav.scrollWidth;
    },
  },
};
</script>

<style>
.tab-container {
  position: relative;
}

.custom-tab-arrow {
  position: absolute;
  top: 0;
  height: 100%;
  transition: linear 0.2s;
  padding: 16px 0;
}

.tab-arrow-left-custom {
  left: 1%;
}

.tab-arrow-right-custom {
  right: 1%;
}
.padding-gap {
  padding: 0 2rem !important;
}
</style>
