const sortVisits = (data) => data.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

export default {
  setVisits: (state, visits) => (state.visits = visits),
  setVisit: (state, visit) => (state.visit = visit),
  setNewPlanedVisit: (state, visit) => {
    state.planedVisits?.data?.push(visit);
    state.planedVisits.data = sortVisits(state.planedVisits.data);
    state.planedVisits.total++;
  },
  setPlanedVisits: (state, visits) => (state.planedVisits = visits),
  setNotCompletedVisits: (state, visits) => (state.notCompletedVisits = visits),
  setCompletedVisits: (state, visits) => (state.completedVisits = visits),
  updateVisitStartDate: (state, { id, startDate }) => {
    state.visit.startDate = startDate;
    state.visits = sortVisits(state.visits);
  },
  updateVisitToNotCompleted: (state, { id, data }) => {
    state.visit.status = data?.status;
    state.visit.notCompletedReason = data?.notCompletedReason;
  },
  updateVisitCompleteCodeExpireDate: (state, completeCodeExpireDate) => {
    state.visit.completeCodeExpireDate = completeCodeExpireDate;
  },
  updateVisitToCompleted: (state, { id, status, completeDate }) => {
    state.visit.status = status;
    state.visit.completeDate = completeDate;
  },
  setVisitCollapseVisible: (state, value) => (state.isVisitCollapseVisible = value),
};
