import appAxios from "@/utils/appAxios";

export default {
  getAssignedCompanies: ({ commit, getters }, loading) => {
    appAxios
      .get("/rest-assignments/grouped-assignments", { loading })
      .then((result) => {
        commit("setAssignedCompanies", result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
