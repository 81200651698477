import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { cleanFields } from "@/global-prototypes";

// Modules
import SecureLS from "secure-ls";
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

// Secure LS

// App State
import appState from "./modules/app-state";
import auth from "./modules/auth";
import visits from "./modules/visits";
import company from "./modules/company";
import employees from "./modules/employees";
import customerUsers from "./modules/customerUsers";
import branch from "./modules/branch";
import isg from "./modules/isg";

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

const appStateModules = {
  appState,
  auth,
  visits,
  company,
  employees,
  customerUsers,
  branch,
  isg,
};

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    ...appStateModules,
  },
  mutations: {
    cleanStore: (state) => {
      Object.keys(appStateModules).map((key) => (state[key] = cleanFields(state[key])));
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userData");
    },
  },
  strict: process.env.DEV,
  plugins: [
    createPersistedState({
      key: "vuex",
      paths: ["company", "branch", "user"],
      storage: window.sessionStorage,
    }),
  ],
});
