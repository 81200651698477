export default {
  isAppSidebarActive: state => state.appSidebar?.show,
  appSidebarTitle: state => state?.appSidebar?.title,
  appSidebarContent: state => state?.appSidebar?.component,
  appSidebarItem: state => state?.appSidebar?.item,
  sidebarItems: state => state.sidebarItems,

  // Modal
  isAppModalActive: state => state.appModal?.show,
  appModalTitle: state => state?.appModal?.title,
  appModalContent: state => state?.appModal?.component,
  appModalItem: state => state?.appModal?.item,
  appModalSize: state => state?.appModal?.size,
  appModalNoFade: state => state?.appModal?.noFade,
  appModalNoHeader: state => state?.appModal?.noHeader,
  appModalNoBodyGap: state => state?.appModal?.noBodyGap,
  appModalCentered: state => state?.appModal?.centered,

  isAppModalActive: state => state.appModal?.show,
  isAppModalSidebarActive: state => state.appModal?.sidebar?.show,
  appModalSidebarTitle: state => state?.appModal?.sidebar?.title,
  appModalSidebarContent: state => state?.appModal?.sidebar?.component,
  appModalSidebarItem: state => state?.appModal?.sidebar?.item,

  loadingOverlay: state => state.loadingOverlay,
  sidebarOverlay: state => state.sidebarOverlay,
  modalOverlay: state => state.modalOverlay,
  selectBoxOverlay: state => state.selectBoxOverlay,
}
