import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  state: {
    visits: [],
    visit: null,
    isVisitCollapseVisible: false,
    planedVisits: [],
    notCompletedVisits: [],
    completedVisits: [],
  },
  actions,
  mutations,
  getters,
};
