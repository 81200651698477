export default {
  detectionAndSuggestions: (state) => state.detectionAndSuggestions,
  nonConfirmities: (state) => state.nonConfirmities,
  observations: (state) => state.observations,
  wsFiles: (state) => state.wsFiles,
  instructions: (state) => state.instructions,
  incidents: (state) => state.incidents,
  trainings: (state) => state.trainings,
  plans: (state) => state.plans,
  documents: (state) => state.documents,
};
