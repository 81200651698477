export default {
  setEmployee: (state, employee) => (state.employee = employee),

  // Support Teams
  setSupportTeams: (state, supportTeams) => (state.supportTeams = supportTeams),
  setNewSupportTeams: (state, supportTeam) => (state.supportTeams = state.supportTeams.concat(supportTeam)),
  deleteSupportTeamEmployee: (state, id) => {
    state.supportTeams = state.supportTeams.filter((item) => item._id !== id);
  },

  setEmployees: (state, employees) => (state.employees = employees),

  setCompanyEmployeeFiles: (state, companyEmployeeFiles) => (state.companyEmployeeFiles = companyEmployeeFiles),
  setNewCompanyEmployeeFile: (state, data) => {
    state.companyEmployeeFiles.data.unshift(data);
    state.companyEmployeeFiles.total++;
  },
  deleteEmployeeFile: (state, id) => {
    state.companyEmployeeFiles.data = state.companyEmployeeFiles.data.filter((item) => item._id !== id);
    state.companyEmployeeFiles.total--;
  },
};
