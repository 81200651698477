import { emitter } from "@/global-prototypes";
import appAxios from "@/utils/appAxios";

export default {
  getCustomerUsers: ({ commit }, query) => {
    appAxios
      .get("customer/list/paginated", { params: { ...query }, loading: "table" })
      .then((result) => {
        commit("setCustomerUsers", result.data);
      })
      .catch((err) => {
        emitter.$emit("Notification", {
          title: "Hata Oluştu",
          variant: "danger",
          message: err?.data?.message ?? null,
        });
      });
  },
  newCustomerUser({ commit }, { data, callback }) {
    appAxios
      .post("/customer/new", data, { loading: "sidebar" })
      .then(() => {
        callback && callback();
        commit("cleanAppSidebar");
        emitter.$emit("Notification", {
          title: "İşlem Başarılı",
          variant: "success",
          message: "Kullanıcı başarıyla sisteme eklendi.",
        });
      })
      .catch((error) => {
        emitter.$emit("Notification", {
          title: "Hata oluştu",
          variant: "danger",
          message: error?.data?.message ?? null,
        });
      });
  },
};
