<template>
  <b-card class="mb-50" :class="{ border: !noBorder }" :body-class="noBodyGap ? 'p-0' : 'p-1'">
    <div v-if="searchable">
      <b-row style="gap: 10px 0px" class="mb-1">
        <b-col lg="8">
          <div class="d-flex gap-5 flex-wrap flex-1-md">
            <slot name="modalbutton" />
          </div>
        </b-col>
        <b-col lg="4">
          <div class="d-flex align-items-center gap-10">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input id="filterInput" v-model="search" placeholder="Ara..." type="search" />
            </b-input-group>
            <b-button v-if="filterable" v-b-toggle="collapseID" variant="gradient-secondary" class="btn-icon rounded-circle" style="padding: 4px">
              <mdi-icon icon="FilterVariant" size="26" />
              <!-- <feather-icon icon="FilterIcon" size="20" /> -->
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-collapse :id="collapseID">
        <b-card body-class="p-1" class="border m-0 mb-1">
          <slot name="filtercollapse" />
        </b-card>
      </b-collapse>
    </div>
    <b-table
      :hover="hover"
      responsive
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortType"
      :filter="local ? search : ''"
      show-empty
      empty-text="Veri Bulunamadı"
      empty-filtered-text="Veri Bulunamadı"
      details-td-class="p-0"
      @row-clicked="rowClicked"
      :no-local-sorting="!local"
    >
      <template v-for="field in fields" :slot="`cell(${field.key})`" slot-scope="data">
        <slot v-if="$scopedSlots[field.key]" :name="field.key" v-bind="data" />
        <div :key="field.key" v-else>
          {{ field.limit && setTargetId(data) }}
          <b-tooltip
            v-if="field.limit && $showValue(data, field, 'data.item.').length > field.limit"
            :target="field.key + data.item.targetId"
            boundary="viewport"
            placement="bottom"
          >
            {{ $showValue(data, field, "data.item.") }}
          </b-tooltip>
          <span :id="field.key + data.item.targetId" v-if="field.limit">
            {{ $fn($showValue(data, field, "data.item."), field.limit) }}
          </span>
          <span v-else>
            {{ $showValue(data, field, "data.item.") }}
          </span>
        </div>
      </template>
      <template #row-details="row">
        <slot name="row-details" v-bind="row" />
      </template>
    </b-table>
    <b-row v-if="totalRows">
      <b-col class="d-flex align-items-center" md="4">
        <div>
          <b-form-select id="limitSelect" v-model.number="limit" class="d-flex" size="sm" :options="customPageOptions" :disabled="disableLimit" />
        </div>
      </b-col>
      <b-col md="4">
        <b-pagination v-model="page" :total-rows="totalRows" :per-page="limit" first-number last-number align="center" size="sm" class="my-50"></b-pagination>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center" md="4">
        <label class="text-sm-right">Toplam {{ totalRows }} kayıt</label>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    hover: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    totalRows: {
      type: [Number, String],
    },
    defaultSortType: {
      type: String,
    },
    defaultSortBy: {
      type: String,
    },
    disableLimit: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    noBodyGap: {
      type: Boolean,
      default: false,
    },
    resizableColumns: {
      type: Boolean,
      default: false,
    },
    useRouteQuery: {
      type: Boolean,
      default: false,
    },
    customPageOptions: {
      type: Array,
      default: () => [10, 25, 50],
    },
    local: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapseID: uuidv4(),
      uuidv4,
      sortType: "desc",
      typingTimeout: null,
      booleanSortDirections: {
        desc: true,
        asc: false,
      },
      sortDirections: {
        true: "desc",
        false: "asc",
      },
      pagination: {
        page: 1,
        search: "",
        limit: 10,
        sortBy: "createdAt",
      },
    };
  },
  computed: {
    page: {
      get() {
        if (this.useRouteQuery) return !this.$route?.query?.page || isNaN(parseInt(this.$route?.query?.page)) ? 1 : parseInt(this.$route?.query?.page);
        return this.pagination?.page;
      },
      set(val) {
        if (this.useRouteQuery) this.setRouteQuery({ page: val });
        else this.pagination.page = val;
        this.sendEmit();
      },
    },
    search: {
      get() {
        if (this.useRouteQuery) return Boolean(this.$route?.query?.search) ? this.$route?.query?.search : "";
        return this.pagination?.search;
      },
      set(val) {
        clearTimeout(this.typingTimeout);

        this.typingTimeout = setTimeout(
          () => {
            if (this.useRouteQuery) this.setRouteQuery({ search: val });
            else this.pagination.search = val;
            this.sendEmit();
          },
          this.local ? 0 : 300
        );
        return val;
      },
    },
    limit: {
      get() {
        if (this.useRouteQuery) {
          const limit = parseInt(this.$route?.query?.limit);
          return !limit || isNaN(limit) || !this.customPageOptions.includes(limit) ? 10 : limit;
        }
        return this?.pagination?.limit;
      },
      set(val) {
        if (this.useRouteQuery) this.setRouteQuery({ limit: val });
        else this.pagination.limit = val;
        this.sendEmit();
      },
    },
    sortBy: {
      get() {
        if (this.useRouteQuery) return this?.$route?.query?.sortBy ?? this.defaultSortBy ?? "createdAt";
        return this.defaultSortBy ?? this.pagination?.sortBy;
      },
      set(val) {
        if (this.useRouteQuery) this.setRouteQuery({ sortBy: val });
        else this.pagination.sortBy = val;
        this.sendEmit();
      },
    },
    sortDesc: {
      get() {
        if (this.useRouteQuery) {
          const queryCheck = !!this?.$route?.query?.sortType;
          const defaultCheck = !!this.defaultSortType;

          const sortDirection = queryCheck ? this.booleanSortDirections[this?.$route?.query?.sortType] : defaultCheck ? this.booleanSortDirections[this.defaultSortType] : true;

          /*  const querycheck = Boolean(this.booleanSortDirections[this?.$route?.query?.sortType]);
          const defaultcheck = Boolean(this.booleanSortDirections[this.defaultSortType]);
          const sortDirection = querycheck ? this.booleanSortDirections[this?.$route?.query?.sortType] : defaultcheck ? this.booleanSortDirections[this.defaultSortType] : true;
          this.sortType = this.sortDirections[sortDirection];
          return sortDirection; */
        }
        return this.booleanSortDirections[this.defaultSortType] ?? this.booleanSortDirections[this.sortType];
      },
      set(val) {
        this.sortType = this.sortDirections[val];
        if (this.useRouteQuery) this.setRouteQuery({ sortType: this.sortType });
        this.sendEmit();
      },
    },
  },
  methods: {
    sendEmit() {
      const query = { page: this.page, limit: this.limit, sortBy: this.sortBy, sortType: this.sortType, search: this.search };
      this.$emit("input", query);
      this.$emit("changed", query);
    },
    setRouteQuery(item) {
      this.$router.replace({ name: this.$route.name, params: { ...this.$route.params }, query: { ...this.$route.query, ...item } }).catch(() => {});
    },
    setTargetId(data) {
      if (data?.item) data.item.targetId = uuidv4();
    },
    rowClicked(row, index, event) {
      this.$emit("row-clicked", row, index, event);
    },
    activateResizableColumns() {
      var thElm;
      var startOffset;

      Array.prototype.forEach.call(document.querySelectorAll("table th"), function (th) {
        th.style.position = "relative";

        var grip = document.createElement("div");
        grip.innerHTML = "&nbsp;";
        grip.style.top = 0;
        grip.style.right = 0;
        grip.style.bottom = 0;
        grip.style.width = "5px";
        grip.style.position = "absolute";
        grip.style.cursor = "col-resize";
        grip.style["border-right"] = "2px solid #EBE9F1";
        grip.addEventListener("mousedown", function (e) {
          thElm = th;
          startOffset = th.offsetWidth - e.pageX;
        });

        th.appendChild(grip);
      });

      document.addEventListener("mousemove", function (e) {
        if (thElm) {
          thElm.style.width = startOffset + e.pageX + "px";
        }
      });

      document.addEventListener("mouseup", function () {
        thElm = undefined;
      });
    },
  },
  mounted() {
    this.sendEmit();
    this.resizableColumns && this.activateResizableColumns();
  },
};
</script>
