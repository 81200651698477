import { emitter } from "@/global-prototypes";
import appAxios from "@/utils/appAxios";

export default {
  getTrainings: ({ commit }, { query, actionType }) => {
    appAxios
      .get("/trainings/paginated", { params: query, loading: "table" })
      .then((result) => {
        commit(actionType, result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  newTraining: ({ commit }, data) => {
    appAxios
      .post("/trainings", data, { loading: "sidebar" })
      .then(({ data }) => {
        commit("newTraining", data);
        emitter.$emit("Notification", { variant: "success", title: "İşlem Başarılı", message: "Eğitim Başarıyla Eklendi." });
        commit("cleanAppSidebar");
      })
      .catch((error) => {
        console.log(error);
        emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu" });
      });
  },
  getPhysicianOptions: (_, roleKey) =>
    new Promise((resolve, reject) => {
      appAxios
        .get("/physicians/options", { params: { roleKey } })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  getWsFiles: ({ commit }, { query, actionType }) => {
    appAxios
      .get("/company/ws-files/paginated", { params: query, loading: "table" })
      .then((result) => {
        commit(actionType, result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
