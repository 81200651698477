import axios from "@/utils/appAxios";
import { emitter } from "@/global-prototypes";
import graphqlRequest from "@/utils/graphqlRequest";

export default {
  newEmployee: ({ state }, { modal, data }) => {
    axios
      .post("/employees", data)
      .then((response) => {
        state.customerEmployees.push(response.data);
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
          message: "Çalışan başarıyla sisteme eklendi.",
        });
        modal.hide();
      })
      .catch(() => {
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata Oluştu",
        });
      });
  },
  getCompanyEmployees: ({ commit }, { query, loading }) => {
    axios
      .get("/employees/employee-options", { params: query, loading: loading ?? "sidebar" })
      .then((result) => {
        commit("setEmployees", result.data);
      })
      .catch((err) => {
        emitter.$emit("Notification", { variant: "danger", title: "Hata oluştu", message: err?.data?.message ?? null });
      });
  },
  getEmployees: ({ commit }, { query, loading }) => {
    axios
      .get("/employees/list/paginated", { params: query, loading: loading ?? "table" })
      .then((result) => {
        commit("setEmployees", result.data);
      })
      .catch((err) => {
        emitter.$emit("Notification", { variant: "danger", title: "Hata oluştu", message: err?.data?.message ?? null });
        console.log(err);
      });
  },
  getEmployeeFiles: ({ commit }, { query, loading }) => {
    axios
      .get("/employees/employee-files/paginated-list", { params: query, loading: loading ?? "modal" })
      .then((result) => {
        commit("setCompanyEmployeeFiles", result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  uploadEmployeeFile({ commit }, { data }) {
    axios
      .post("/employees/upload-employee-file", data, { loading: "sidebar" })
      .then((result) => {
        commit("setNewCompanyEmployeeFile", result.data);
        commit("cleanAppModalSidebar");
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
          message: "Dosya başarıyla yüklendi.",
        });
      })
      .catch((err) => {
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata Oluştu",
          message: err?.data?.message ?? null,
        });
      });
  },
  deleteEmployeeFile({ commit }, { id }) {
    axios
      .delete(`employees/employee-files/${id}`, { loading: "modal" })
      .then(() => {
        commit("deleteEmployeeFile", id);
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
          message: "Dosya başarıyla silindi.",
        });
      })
      .catch((err) => {
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata Oluştu",
          message: err?.data?.message ?? null,
        });
      });
  },
};
