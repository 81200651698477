export const initialAbility = [
  {
    action: "read",
    subject: "Auth",
  },
];
export const customerAbility = [
  {
    action: "read",
    subject: "customer",
  },
  {
    action: "read",
    subject: "first_login",
  },
  {
    action: "read",
    subject: "Auth",
  },
  {
    action: "read",
    subject: "error",
  },
];
export const physicianAbility = [
  {
    action: "read",
    subject: "physician",
  },
  {
    action: "read",
    subject: "first_login",
  },
  {
    action: "read",
    subject: "Auth",
  },
  {
    action: "read",
    subject: "error",
  },
];

export const getAbility = (role) => {
  const abilities = {
    customer: customerAbility,
    physician: physicianAbility,
  };
  return abilities[role] ?? initialAbility;
};

export const _ = undefined;
