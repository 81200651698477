import axios from "@/utils/appAxios";
import { emitter } from "@/global-prototypes";

export default {
  getVisits: ({ commit }, { query, actionType }) => {
    axios
      .get("/visits/list/paginated", { params: { ...query }, loading: "table" })
      .then((response) => {
        commit(actionType, response.data);
      })
      .catch((error) => {
        emitter.$emit("Notification", { title: "Hata Oluştu.", message: error?.data?.message ?? null, variant: "danger" });
        console.log(error);
      });
  },
  newVisit: ({ commit }, { data, actionType }) => {
    axios
      .post("/visits", data, { loading: "sidebar" })
      .then((response) => {
        commit(actionType, response.data);
        commit("cleanAppSidebar");
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
          message: "Ziyaret sisteme eklendi.",
        });
      })
      .catch((error) => {
        console.log(error);
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata Oluştu",
          message: error?.data?.message ?? null,
        });
      });
  },
  updateVisitStartDate({ commit }, { id, startDate }) {
    axios
      .patch(`/visits/${id}/update-start-date`, { startDate }, { loading: "sidebar" })
      .then((result) => {
        commit("updateVisitStartDate", { id, startDate: result.data.startDate });
        commit("cleanAppSidebar");
        emitter.$emit("Notification", { title: "Ziyaret Tarihi Güncellendi.", variant: "success" });
      })
      .catch((err) => {
        emitter.$emit("Notification", { title: "Hata Oluştu.", message: err?.data?.message ?? null, variant: "danger" });
        console.log(err);
      });
  },
  updateVisitToNotCompleted({ commit }, { id, data }) {
    axios
      .patch(`/visits/${id}/not-completed`, data, { loading: "sidebar" })
      .then((result) => {
        commit("cleanAppSidebar");
        commit("updateVisitToNotCompleted", { id, data: result.data });
        emitter.$emit("Notification", { title: "Ziyaret Durumu Güncellendi.", variant: "success" });
      })
      .catch((err) => {
        emitter.$emit("Notification", { title: "Hata Oluştu.", message: err?.data?.message ?? null, variant: "danger" });
        console.log(err);
      });
  },
};
