<template>
  <b-list-group flush>
    <b-list-group-item
      v-for="item in fields"
      :key="item.key"
    >
      <b-row>
        <b-col
          class="p-0"
          cols="5"
        >
          <span class="font-weight-bolder d-flex align-items-center"> <feather-icon
            v-if="item.icon"
            :icon="item.icon"
            class="mr-75"
          /> {{ item.label }} : </span>
        </b-col>
        <b-col cols="7">
          <slot
            v-if="$scopedSlots[item.key]"
            :name="item.key"
            v-bind="data"
          />
          <slot v-else>
            {{ $showValue(data, item, "data.") }}
          </slot>
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    fields: {
      type: Array,
    },
  },
}
</script>

<style></style>
