import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  state: {
    selectedCompany: null,
    employee: null,
    customerEmployees: [],
    assignedCompanies: [],
    officials: [],
    officialNotes: [],
    isCompanyCollapseVisible: false,
  },
  actions,
  mutations,
  getters,
};
