<template>
  <b-card no-body :class="{ open: visible }" @mouseenter="collapseOpen" @mouseleave="collapseClose">
    <b-card-header
      :class="{ collapsed: !visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible), $emit('click', visible)"
    >
      <slot name="header">
        <span v-if="titleSize === 'sm'" class="lead collapse-title">{{ title }}</span>
        <h4 v-else>
          {{ title }}
        </h4>
      </slot>
    </b-card-header>

    <b-collapse :id="collapseItemID" v-model="visible" :accordion="accordion" role="tabpanel">
      <b-card-body :class="{ 'p-0': windowWidth < 767 }">
        <slot v-if="lazyCheck" />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    titleSize: {
      type: String,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: "",
      lazyCheck: false,
      openOnHover: this.$parent.hover,
    };
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null;
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
  },
  watch: {
    visible(val) {
      if (this.lazy) {
        !val ? setTimeout(() => (this.lazyCheck = val), 300) : (this.lazyCheck = val);
      } else this.lazyCheck = true;
    },
  },
  created() {
    this.collapseItemID = uuidv4();
    this.visible = this.isVisible;
    !this.lazy && (this.lazyCheck = true);
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val;
      this.$emit("visible", val);
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true);
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false);
    },
  },
};
</script>

<style>
.card-header::after {
  background-size: 25px !important;
  margin-right: 20px;
}
</style>
