<template>
  <app-select-input
    v-model="company"
    :selectable="(option) => !option.group"
    label="Firma:"
    name="Firma"
    :options="options"
    select_label="name"
    placeholder="Firma Seçiniz..."
    :reduce="reduce"
    :rules="required ? 'required' : ''"
    @input="$emit('input', company)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String | Object,
    },
    reduce: {
      type: Function,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      company: null,
      options: [],
    }
  },
  watch: {
    value(item) {
      this.company = item
    },
  },
  mounted() {
    this.company = this.value
    this.getCompanyOptions()
  },
  methods: {
    getCompanyOptions() {
      this.$store
        .dispatch('getCompanyOptions')
        .then(options => {
          this.options = options
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style></style>
