import { getHomeRouteForLoggedInUser, getUserData } from "@/auth/utils";
import { getAbility } from "@/libs/acl/config";
import app from "@/main";
import store from "@/store";
import appAxios from "@/utils/appAxios";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Auth/Login.vue"),
    meta: {
      layout: "full",
      redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/first-login",
    name: "first-login",
    component: () => import("@/views/Auth/FirstLogin/index.vue"),
    beforeEnter: (to, from, next) => {
      next();
      const userData = getUserData();
      userData.first_login ? next(getHomeRouteForLoggedInUser(userData?.role?.group)) : next();
    },
    meta: {
      layout: "full",
      resource: "first_login",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/Auth/ForgotPassword/ForgotPassword.vue"),
    meta: {
      layout: "full",
      guest: true,
      redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/change-password",
    name: "forgot-password-change",
    beforeEnter: (to, _, next) => {
      store.commit("cleanStore");
      if (!to.query?.token) return next({ name: "error-404" });
      next();
    },
    component: () => import("@/views/Auth/ForgotPassword/ChangePassword.vue"),
    meta: {
      layout: "full",
      guest: true,
      redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/web-login",
    name: "web-login",
    beforeEnter: (to, from, next) => {
      from.meta.layout = "full";
      sessionStorage.removeItem("token");
      if (!to.query?.token) return next({ name: "error-404" });
      const token = to?.query?.token;
      appAxios
        .get("/token-login", { params: { token } })
        .then((response) => {
          sessionStorage.setItem("token", token);
          store.commit("setUser", { ...response.data, token });
          response.data.first_login ? next({ name: "dashboard" }) : next({ name: "first-login" });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            store.commit("cleanStore");
            return next({ name: "login" });
          }
          sessionStorage.removeItem("token");
          store.commit("cleanStore");
          next({ name: "error-404" });
        });
    },
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/company-choose",
    name: "company-choose",
    component: () => import("@/views/Auth/CompanyChoose.vue"),
    meta: {
      layout: "full",
      requiresAuth: true,
      resource: "customer",
    },
  },
  {
    path: "/:id",
    beforeEnter: (to, from, next) => {
      from.meta.layout = "full";
      const smsId = to.params.id;
      if (!smsId) return next();

      appAxios
        .post("/smsId-login", { smsId })
        .then((result) => {
          sessionStorage.removeItem("token");
          store.commit("cleanStore");
          sessionStorage.setItem("token", result.data.token);
          sessionStorage.setItem("userData", JSON.stringify(result.data));
          store.commit("setUser", { ...result.data, token: result.data.token });
          appAxios.defaults.headers.common["Authorization"] = result.data.token;
          app.$ability.update(getAbility(result.data?.role?.group));
          next({ name: "first-login" });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            store.commit("cleanStore");
            return next({ name: "login" });
          }
          next({ name: "error-404" });
        });
    },
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  /*   {
    path: "/company",
    name: "company-choose",
    component: () => import("@/views/Auth/CompanyChoose.vue"),
    meta: {
      layout: "full",
      guest: true,
    },
  }, */
];
