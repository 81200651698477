<template>
  <b-overlay :variant="$store.state.appConfig.layout.skin" :show="$store.getters[loadingVariable]" spinner-variant="danger" blur="0" opacity="0.70" rounded="sm">
    <slot />
  </b-overlay>
</template>

<script>
export default {
  props: {
    loadingVariable: {
      type: String,
      default: "loadingOverlay",
    },
  },
};
</script>

<style></style>
