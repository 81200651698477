import store from "@/store";

export default [
  {
    path: "/customer/employees",
    name: "customerEmployees",
    component: () => import("@/views/Customers/employees/list.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
];
