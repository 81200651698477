<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <global-app-sidebar />
    <global-app-modal />
    <component :is="layout">
      <router-view />
    </component>
    <scroll-to-top />
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import ScrollToTop from "./@core/components/scroll-to-top/ScrollToTop.vue";
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";
import globalAppModal from "./views/app-components/modals/globalAppModal.vue";
import globalAppSidebar from "./views/app-components/modals/globalAppSidebar.vue";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () => import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    globalAppSidebar,
    globalAppModal,
    ScrollToTop,
  },
  data() {
    return {
      tokenInterval: null,
      loadingOverlays: {
        table: {
          active: "activeLoadingOverlay",
          disable: "disableLoadingOverlay",
        },
        sidebar: {
          active: "activeSidebarOverlay",
          disable: "disableSidebarOverlay",
        },
        modal: {
          active: "activeModalOverlay",
          disable: "disableModalOverlay",
        },
        selectBox: {
          active: "activeSelectBoxOverlay",
          disable: "disableSelectBoxOverlay",
        },
      },
    };
  },
  computed: {
    layout() {
      const layout = this.$route.meta.loadingLayout ?? this.$route.meta.layout;
      if (layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    user() {
      return this.$store.getters.user;
    },
    token() {
      return sessionStorage.getItem("token");
    },
  },
  watch: {
    token(val) {
      this.$socket.emit("config", { token: val });
    },
  },
  mounted() {
    window.addEventListener("beforeunload", this.handleClose);

    document.addEventListener("mousemove", this.handleMouseMove);
    window.addEventListener("online", () => {
      if (this.$route.name === "network-error") {
        this.$router.push(this.user ? { name: "dashboard" } : { name: "login" });
      }
    });
    window.addEventListener("offline", () => {
      this.$router.push({ name: "network-error" });
    });
    this.$store.commit("disableLoadingOverlay");
    this.$store.commit("cleanAppSidebar");
    this.$store.commit("cleanAppModal");
    this.$socket.emit("config", { token: this.token });

    this.$axios.interceptors.request.use(
      (request) => {
        if (request?.loading) this.setOverlay(request?.loading, "active");

        return request;
      },
      (error) => {
        if (error?.config?.loading) this.setOverlay(error.config.loading, "disable");
        return Promise.reject(error.response);
      }
    );
    this.$axios.interceptors.response.use(
      (response) => {
        if (response.config?.loading) this.setOverlay(response.config.loading, "disable");
        return response;
      },
      (error) => {
        if (error?.config?.loading) this.setOverlay(error.config.loading, "disable");
        /* if (error?.response?.status === 401) this.$store.dispatch("logout"); */

        if (
          error?.request?.responseType === "blob" &&
          error.response.data instanceof Blob &&
          error.response.data.type &&
          error.response.data.type.toLowerCase().indexOf("json") != -1
        ) {
          return Promise.reject(error.response.data.text());
        }

        return Promise.reject(error.response);
      }
    );
    this.$emitter.$on("Notification", (data) => {
      const variantIcon = {
        success: "CheckCircleIcon",
        danger: "AlertTriangleIcon",
        info: "InfoIcon",
      };
      const defaultTitle = {
        success: "İşlem Başarılı",
        danger: "Hata Oluştu",
      };
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: data.title ?? defaultTitle[data?.variant],
            icon: variantIcon[data?.variant] ?? "CheckCircleIcon",
            variant: data?.variant,
            text: data?.message,
          },
        },
        {
          position: "top-center",
        }
      );
    });
    this.$socket.on("NEW_VERSION_UPDATED_PANEL", () => {
      this.$confirm({ message: "Yeni bir güncelleme mevcut. Güncellemek için sayfayı yenilemek ister misiniz?" }, () => {
        window.location.reload();
      });
    });
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ["primary", "secondary", "success", "info", "warning", "danger", "light", "dark"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2));
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });
    return {
      skinClasses,
    };
  },
  destroyed() {
    this.$socket.off("NEW_VERSION_UPDATED_ADMIN");
    document.removeEventListener("mousemove", this.handleMouseMove);
    window.removeEventListener("beforeunload", this.handleClose);
  },
  methods: {
    handleMouseMove() {
      if (this.user && process.env.NODE_ENV === "production") {
        clearInterval(this.tokenInterval);
        this.tokenInterval = setInterval(() => {
          if (!this.user) return clearInterval(this.tokenInterval);
          this.$store.dispatch("logout", { sendLogoutReq: true });
        }, 10 * 60 * 1000);
      }
    },
    setOverlay(val, action) {
      const overlay = this.loadingOverlays[val] ? this.loadingOverlays[val][action] : null;
      overlay && this.$store.commit(overlay);
    },
    handleClose() {
      /*  this.$axios
        .get("/company/deneme")
        .then((result) => {})
        .catch((err) => {}); */
    },
  },
};
</script>
