<template>
  <b-button
    :variant="variant"
    :class="size === 'md' ? 'py-75 px-1' : block ? '' : 'p-50'"
    :block="block"
    :href="href"
    :target="target"
    @click="$emit('click')"
  >
    <span
      :class="`d-flex justify-content-center align-items-center gap-3 fs-${size === 'md' || block ? '14' : '13'} ${iconPosition === 'left' ? 'flex-row-reverse' : ''}`"
      style="white-space: nowrap"
    >
      {{ text }}<feather-icon
        v-if="icon"
        :icon="icon"
        :size="size === 'md' ? '18' : '16'"
      />
    </span>
  </b-button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    text: {
      type: String,
    },
    size: {
      type: String,
    },
    iconPosition: {
      type: String,
    },
    block: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
    },
    target: {
      type: String,
    },
    variant: {
      type: String,
      default: 'gradient-primary',
    },
  },
}
</script>

<style lang="scss">
@for $i from 1 through 20 {
  .gap-#{$i} {
    gap: #{$i} + "px";
  }
}
@for $i from 1 through 20 {
  .fs-#{$i} {
    font-size: #{$i} + "px";
  }
}
</style>
