<template>
  <app-select-input
    v-model="selected"
    :options="options"
    select_label="value"
    name="Sektör"
    label="Sektör:"
    placeholder="Sektör Seçiniz..."
    :rules="required ? 'required' : ''"
    :reduce="reduce"
    :multiple="multiple"
    @input="$emit('input', $event)"
  >
    <template
      v-if="multiple"
      #list-header
    >
      <div class="p-50 m-25 border rounded d-flex gap-3">
        <app-button
          text="Tümünü Seç"
          @click="selectAll"
        />
        <app-button
          text="Seçimleri Kaldır"
          @click="deSelectAll"
        />
      </div>
    </template>
  </app-select-input>
</template>

<script>
export default {
  props: {
    value: { type: String | Object },
    required: { type: Boolean, default: false },
    reduce: { type: Function },
    multiple: { type: Boolean, default: false },
  },
  data() {
    return {
      options: [],
      selected: null,
    }
  },
  watch: {
    value(item) {
      this.selected = item
    },
  },
  mounted() {
    this.getSectors()
    this.selected = this.value
  },
  methods: {
    getSectors() {
      this.$axios
        .get('/option-data/sectors')
        .then(response => {
          this.options = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    selectAll() {
      this.selected = []
      const options = this.reduce ? this.reduceItems(this.options) : this.options
      this.selected = [...options]
      this.$emit('input', this.selected)
    },
    deSelectAll() {
      this.selected = []
      this.$emit('input', this.selected)
    },
    reduceItems(items) {
      return items.map(this.reduce)
    },
  },
}
</script>

<style></style>
