import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

export default {
  state: {
    appSidebar: {
      show: false,
    },
    appModal: {
      show: false,
      sidebar: {
        show: false,
      },
    },
    sidebarOverlay: false,
    modalOverlay: false,
    selectBoxOverlay: false,
    sidebarItems: [],
    loadingOverlay: false,
  },
  actions,
  mutations,
  getters,
};
