import isg from "./isg";

export default [
  ...isg,
  {
    path: "/physician/profile",
    name: "physician-profile",
    component: () => import("@/views/Physicians/userSettings/index.vue"),
    meta: {
      requiresAuth: true,
      resource: "physician",
    },
  },
  {
    path: "/physician/visit-detail/:id",
    name: "physician-visit-detail",
    props: true,
    component: () => import("@/views/Physicians/Visits/details/VisitDetailModal.vue"),
    meta: {
      requiresAuth: true,
      resource: "physician",
    },
  },
];
