<template>
  <app-select-input
    v-model="district"
    :options="options"
    name="Mahalle"
    label="Mahalle:"
    placeholder="Mahalle Seçiniz..."
    select_label="value"
    :rules="required ? 'required' : ''"
    :reduce="reduce"
    @input="$emit('input', $event), $emit('selected', $event)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String | Object,
    },
    required: { type: Boolean, default: false },
    reduce: { type: Function },
    county: { type: Object | String },
  },
  data() {
    return {
      district: null,
      options: [],
    }
  },
  watch: {
    county(item) {
      if (item) {
        this.setDistricts(item._id)
      } else {
        this.options = []
        this.$emit('input', null)
      }
    },
    value(item) {
      this.district = item
    },
  },
  mounted() {
    this.setDistricts(this?.county?._id)
    this.district = this.value
  },
  methods: {
    setDistricts(county) {
      this.district = null
      if (county) {
        this.$axios
          .get(`option-data/district?county=${county}`)
          .then(response => {
            this.options = response.data
          })
          .catch(error => {
            console.log(error)
          })
      } else this.$emit('input', null)
    },
  },
}
</script>

<style></style>
