<template>
  <div>
    <b-modal
      :id="id"
      v-model="visible"
      :body-class="{ 'p-0': noBodyGap }"
      :hide-header="noHeader"
      cancel-variant="outline-secondary"
      hide-footer
      :centered="centered"
      :title="title"
      :size="size"
      :no-fade="noFade"
      lazy
      body-bg-variant="transparent"
      no-close-on-backdrop
      no-close-on-esc
      @change="changed"
      :dialog-class="{ 'modal-centered-md': !centered }"
    >
      <slot />
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    title: {
      type: String,
    },
    size: {
      type: String,
      default: "lg",
    },
    noFade: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    noBodyGap: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: true,
    },
    /*  width: {
      type: String,
      default: "500px",
    }, */
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    visible(val) {
      if (val) this.$emit("visible");
    },
  },
  methods: {
    changed(val) {
      if (!val) this.$emit("hide");
    },
    hide() {
      this.visible = false;
    },
    show() {
      this.visible = true;
    },
  },
};
</script>

<style>
@media only screen and (min-width: 1300px) {
  .modal .modal-image {
    max-width: fit-content !important;
  }
}
.modal .modal-custom {
  max-width: 95%;
}

@media only screen and (max-width: 480px) {
  .modal .modal-custom .modal-content {
    min-height: fit-content;
  }
  .modal {
    padding: 0.3rem !important;
  }
  .modal-centered-md {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
  }
  .modal-body {
    padding: 0.5rem !important;
  }
}
</style>
