import appAxios from "@/utils/appAxios";

export default {
  getCompanyOptions() {
    return new Promise((resolve, reject) => {
      axios
        .get("/company/list/company-options")
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSidebarItems: ({ commit }, roleGroup) => {
    appAxios
      .get("sidebar-items", { params: { roleGroup: roleGroup || "global" } })
      .then((response) => {
        commit("setSidebarItems", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getBranchOptions(_, company) {
    return new Promise((resolve, reject) => {
      appAxios
        .get("company/branches/list/branch-options", { params: { company } })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
