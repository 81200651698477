<template>
  <div>
    <b-sidebar :id="id" v-model="visible" bg-variant="white" sidebar-class="sidebar-lg" right backdrop shadow no-header lazy @change="changed">
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ title }}
          </h5>
          <div class="d-flex gap-5">
            <app-icon-button class="rounded-circle" variant="gradient-secondary" icon="XIcon" @click="hide()" />
          </div>
        </div>
        <div class="container mt-2">
          <slot />
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    visible(val) {
      if (val) this.$emit("visible");
    },
  },
  methods: {
    changed(val) {
      if (!val) this.$emit("hide");
    },
    hide() {
      this.visible = false;
    },
    show() {
      this.visible = true;
    },
  },
};
</script>

<style></style>
