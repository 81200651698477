export default [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/Dashboard/index.vue"),

    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customers",
    name: "customer-dashboard",
    component: () => import("@/views/Customers/Dashboard/index.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
  {
    path: "/physicians",
    name: "physician-dashboard",
    component: () => import("@/views/Physicians/dashboard/index.vue"),
    meta: {
      requiresAuth: true,
      resource: "physician",
    },
  },
];
