<template>
  <div>
    <b-dropdown text="Primary" variant="link" offset="55" toggle-class="text-decoration-none p-0" :right="position === 'right'" no-caret boundary="viewport">
      <template #button-content>
        <slot v-if="$scopedSlots['button-content']" name="button-content" />
        <div v-else>
          <b-button variant="gradient-danger" :class="'p-50 btn-icon rounded-circle'">
            <feather-icon icon="ChevronDownIcon" size="15" />
          </b-button>
        </div>
      </template>
      <slot />
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: "right",
    },
  },
};
</script>

<style></style>
