export default {
  activeLoadingOverlay: (state) => (state.loadingOverlay = true),
  disableLoadingOverlay: (state) => (state.loadingOverlay = false),
  activeSidebarOverlay: (state) => (state.sidebarOverlay = true),
  disableSidebarOverlay: (state) => (state.sidebarOverlay = false),
  activeModalOverlay: (state) => (state.modalOverlay = true),
  disableModalOverlay: (state) => (state.modalOverlay = false),
  activeSelectBoxOverlay: (state) => (state.selectBoxOverlay = true),
  disableSelectBoxOverlay: (state) => (state.selectBoxOverlay = false),
  setAppSidebarOptions: (state, options) => {
    state.appSidebar = {
      ...state.appSidebar,
      ...options,
    };
  },
  cleanAppSidebar: (state) => {
    state.appSidebar = {
      show: false,
    };
  },
  setAppModalOptions: (state, options) => {
    state.appModal = {
      ...state.appModal,
      ...options,
    };
  },
  setAppModalSidebarOptions: (state, options) => {
    state.appModal = {
      ...state.appModal,
      sidebar: {
        ...state.appModal.sidebar,
        ...options,
      },
    };
  },
  cleanAppModal: (state) => {
    state.appModal = {
      show: false,
    };
  },
  cleanAppModalSidebar: (state) => {
    state.appModal = {
      ...state.appModal,
      sidebar: {
        show: false,
      },
    };
  },
  setSidebarItems: (state, items) => (state.sidebarItems = items),
};
