<template>
  <b-card>
    <b-row>
      <b-col
        lg="4"
        md="6"
        class="d-flex justify-content-start align-items-start px-2 py-1"
      >
        <div class="d-flex">
          <b-avatar
            :text="title.slice(0, 1)"
            variant="secondary"
            size="92px"
          />
          <div class="d-flex flex-column ml-75 justify-content-center">
            <div class="mb-75">
              <h2 class="mb-0">
                <span class="demo-2"> {{ title }} </span>
              </h2>
              <hr class="m-50">
              <span class="card-text">{{ alt_title }}</span>
            </div>
          </div>
        </div>
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <slot name="center" />
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <slot name="right" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    title: {
      type: String,
    },
    alt_title: {
      type: String | Number,
    },
  },
}
</script>

<style>
/* .demo-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.demo-2:hover {
  overflow: visible;
} */
</style>
