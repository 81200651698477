import isg from "./isg";

export default [
  ...isg,
  {
    path: "/accounting",
    name: "accounting",
    component: () => import("@/views/Customers/Accounting/index.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
  {
    path: "/customer/invoices",
    name: "invoices",
    component: () => import("@/views/Customers/Invoices/invoiceList.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
  {
    path: "/customer/payment",
    name: "customer-payment",
    component: () => import("@/views/Customers/Invoices/payment/payment.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
  {
    path: "/customer/auto-payment",
    name: "customer-auto-payment",
    component: () => import("@/views/Customers/Invoices/payment/autoPayment.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
  {
    path: "/customer/company-info",
    name: "customer-company-info",
    component: () => import("@/views/Customers/companyInfo/index.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
  {
    path: "/customer/todo",
    name: "customer-todo",
    component: () => import("@/views/Customers/todo/Todo.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "todo-application",
      contentRenderer: "sidebar-left",
      resource: "customer",
    },
  },
  {
    path: "/customer/teklifer",
    name: "customer-teklifler",
    component: () => import("@/views/Customers/teklifler/index.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
  {
    path: "/customer/filo",
    name: "customer-filo",
    component: () => import("@/views/Customers/filo/index.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
  {
    path: "/customer/profile",
    name: "customer-profile",
    props: true,
    component: () => import("@/views/Customers/Profile/index.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
  {
    path: "/customer/trainings",
    name: "customer-trainings",
    props: true,
    component: () => import("@/views/Customers/ISG/trainings/TrainingList.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
];
