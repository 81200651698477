export default [
  {
    path: "/customer/isg/visits",
    name: "customer-isg-visits",
    component: () => import("@/views/Customers/ISG/visits/Visits.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
  {
    path: "/customer/visit-detail/:id",
    name: "customer-visit-detail",
    props: true,
    component: () => import("@/views/Customers/ISG/visits/details/VisitDetailModal.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
  {
    path: "/customer/isg/actions",
    name: "customer-isg-actions",
    component: () => import("@/views/Customers/ISG/actions/index.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
  {
    path: "/customer/isg/plans",
    name: "customer-isg-plans",
    component: () => import("@/views/Customers/ISG/plans/list.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
  {
    path: "/customer/isg/documents",
    name: "customer-isg-documents",
    component: () => import("@/views/Customers/ISG/documents/index.vue"),
    meta: {
      requiresAuth: true,
      resource: "customer",
    },
  },
];
