<template>
  <app-select-input
    v-model="branch"
    label="Sicil:"
    name="Sicil"
    :selectable="selectable"
    :options="options"
    select_label="name"
    placeholder="Sicil Seçiniz..."
    :reduce="reduce"
    :rules="required ? 'required' : ''"
    @input="$emit('input', branch)"
    :loading="loading"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String | Object,
    },
    reduce: {
      type: Function,
    },
    company: {
      type: String,
      default: null,
    },
    selectable: {
      type: Function,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      branch: null,
      options: [],
      loading: false,
    };
  },
  watch: {
    value(item) {
      this.branch = item;
    },
    company() {
      this.getBranchOptions();
    },
  },
  mounted() {
    this.getBranchOptions();
    if (this.value) {
      this.branch = this.value;
      this.getBranchOptions();
    }
  },
  methods: {
    getBranchOptions() {
      this.loading = true;

      this.$store
        .dispatch("getBranchOptions", this.company)
        .then((options) => {
          this.loading = false;
          this.options = options;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
