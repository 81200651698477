import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  state: {
    detectionAndSuggestions: [],
    nonConfirmities: [],
    observations: [],
    wsFiles: [],
    instructions: [],
    incidents: [],
    trainings: [],
    employeeTrainings: [],
    plans: [],
    documents: [],
  },
  actions,
  mutations,
  getters,
};
