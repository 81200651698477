import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";

import tr from "vee-validate/dist/locale/tr.json";
import { localize } from "vee-validate";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";
import "./global-prototypes";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

localize("tr", tr);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
  productionTip: false,
}).$mount("#app");

export default app;
