import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import appAxios from "@/utils/appAxios";
import General from "./routes";
import Additional from "./routes/additional";
import auth from "./routes/auth";
import company from "./routes/company";
import employee from "./routes/employee";
import visits from "./routes/visits";
import customer from "./routes/customer";
import physician from "./routes/physician";
import { getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn } from "@/auth/utils";
import { canNavigate } from "@/libs/acl/routeProtection";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
  routes: [
    ...General,
    ...Additional,
    ...auth,
    ...company,
    ...employee,
    ...visits,
    ...customer,
    ...physician,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  from.meta.loadingLayout = "full";

  const isLoggedIn = isUserLoggedIn();
  if (!canNavigate(to)) {
    if (!isLoggedIn) {
      store.commit("cleanStore");
      return next({ name: "login" });
    }
    if (to.name !== "unauthorized") return router.push({ name: "unauthorized" }).catch(() => {});
  }
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData?.role?.group : null));
  }
  next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(async (to, from) => {
  delete from?.meta?.loadingLayout;
  const appLoading = document.getElementById("loading-bg");

  if (appLoading) appLoading.style.display = "none";
  try {
    const result = await appAxios.get("/version/panel");
    const appVersion = result.data.version;
    const currentVersion = localStorage.getItem("appVersion");
    if (!currentVersion) localStorage.setItem("appVersion", appVersion);
    else if (appVersion?.toString() !== currentVersion?.toString()) {
      localStorage.setItem("appVersion", appVersion);
      window.location.reload();
    }

    const token = sessionStorage.getItem("token");
    if (token && from.name !== "login") {
      const r = await appAxios.get("/token-login");
      const user = r.data;
      sessionStorage.setItem("userData", JSON.stringify(user));
      appAxios.defaults.headers.common.Authorization = token;
      store.commit("setUser", user);
      if (!user.first_login) return router.push({ name: "first-login" }).catch(() => {});
      if (user?.companies?.length) {
        const selectedCompany = store.getters.selectedCompany;
        if (!selectedCompany && user?.companies?.length === 1) {
          const [company] = user?.companies;
          store.commit("setSelectedCompany", company);
        } else if (!selectedCompany && user?.companies?.length > 1 && to.name !== "company-choose") {
          router.push({ name: "company-choose" }).catch(() => {});
        }
      }
    }
  } catch (error) {
    if (error?.status === 401) {
      store.dispatch("logout", { sendLogoutReq: false });
    }
    router.push({ name: "error-404" }).catch(() => {});
  }
});

export default router;
