import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  state: {
    assignedBranches: [],
    branchData: null,
    selectedBranch: null,
    branches: [],
    isBranchCollapseVisible: false,
  },
  actions,
  mutations,
  getters,
};
