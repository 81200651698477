<template>
  <div :class="`${'nav-button-' + variant} d-flex ${text && 'gap-5'}`">
    <div class="d-none d-lg-block">
      {{ text }}
    </div>
    <feather-icon
      class="cursor-pointer"
      :icon="icon"
      size="21"
    />
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'danger',
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
}
</script>

<style></style>
